





























































































































































import { Component, Vue } from 'vue-property-decorator'
import Spinner from '@/components/atoms/Spinner.vue'
import BodyTempIcon from '@/components/atoms/Icon/BodyTemp.vue'
import Badge from '@/components/atoms/Badge.vue'
import MedicalInterviewIcon from '@/components/atoms/Icon/MedicalInterviewIcon.vue'
import MedicalInterview from '@/components/molecules/MedicalInterview.vue'
import VABox from 'va/widgets/VABox.vue'
import conditionsStore, { TCondition, TMedicalInterviewData, TConditionMeta } from '@/store/Conditions'
import symptomStore, { Symptom } from '@/store/Symptoms'
import withMessageStore, { WithMessage } from '@/store/WithMessage'
import bodyTempStore from '@/store/BodyTemp'
import dayjs from '@/libs/dayjs'
import SortModal, { SelectOption } from '@/components/molecules/SortModal.vue'
import SortableTableHeader from '@/components/atoms/SortableTableHeader.vue'

@Component({
  components: {
    SortableTableHeader,
    SortModal,
    VABox,
    BodyTempIcon,
    MedicalInterviewIcon,
    Badge,
    MedicalInterview,
    Spinner,
  },
})
export default class extends Vue {
  isLoading = false
  medicalInterviewData: TMedicalInterviewData | null = null
  totalStartPage = 1
  displayPageCountHalf = 2
  sortKey?: string = ''
  sortType?: string = ''
  filterValues?: (number | string)[]

  get conditions(): TCondition[] {
    return conditionsStore.conditions
  }

  get conditionMeta(): TConditionMeta {
    return conditionsStore.meta
  }

  get bodyTempSortOptions(): SelectOption[] {
    return bodyTempStore.sortOptions
  }

  get symptoms(): Symptom[] {
    return [{ id: '0', name: '無症状' }].concat(symptomStore.symptoms)
  }

  get symptomSortOptions(): SelectOption[] {
    return symptomStore.sortOptions
  }

  get withMessages(): WithMessage[] {
    return withMessageStore.withMessages
  }

  get messageSortOptions(): SelectOption[] {
    return withMessageStore.sortOptions
  }

  get totalEndPage(): number {
    return this.conditionMeta.lastPage
  }

  get displayStartPage(): number {
    const startPage = this.conditionMeta.page - this.displayPageCountHalf
    if (startPage < this.totalStartPage) {
      return this.totalStartPage
    }
    return startPage
  }

  get displayEndPage(): number {
    const endPage = this.conditionMeta.page + this.displayPageCountHalf
    if (endPage > this.totalEndPage) {
      return this.totalEndPage
    }
    return endPage
  }

  get pagenationNums(): number[] {
    return [...Array(this.displayEndPage + 1).keys()].slice(this.displayStartPage)
  }

  get isFirstPage(): boolean {
    return this.conditionMeta.page === this.totalStartPage
  }

  get isLastPage(): boolean {
    return this.conditionMeta.page === this.totalEndPage
  }

  get sortKeys(): { BODY_TEMP: string; SYMPTOMS: string; MESSAGE: string } {
    return {
      BODY_TEMP: 'bodyTemp',
      SYMPTOMS: 'withSymptoms',
      MESSAGE: 'message',
    }
  }

  get isDesc(): boolean {
    return this.sortType === 'desc'
  }

  async changePage(page: number): Promise<void> {
    this.isLoading = true
    try {
      await conditionsStore.fetch({
        date: dayjs(),
        page,
        sortKey: this.sortKey,
        sortType: this.sortType,
        filterValues: this.filterValues,
      })
    } finally {
      this.isLoading = false
    }
  }

  /** 問診票モーダル表示 */
  medicalInterviewOpen(medicalInterviewData: TMedicalInterviewData): void {
    this.medicalInterviewData = medicalInterviewData
    const medicalInterview = this.$refs.medicalInterview as MedicalInterview
    medicalInterview.open()
  }

  openBodyTempSortModal(): void {
    const sortModal = this.$refs.bodyTempSortModal as SortModal
    sortModal.open()
  }

  openSymptomSortModal(): void {
    const sortModal = this.$refs.symptomSortModal as SortModal
    sortModal.open()
  }

  openMessageSortModal(): void {
    const sortModal = this.$refs.messageSortModal as SortModal
    sortModal.open()
  }

  async sort(sortKey: string, sortType: string, filterValues: (number | string)[]): Promise<void> {
    this.isLoading = true
    try {
      await conditionsStore.fetch({ date: dayjs(), page: 1, sortKey, sortType, filterValues })
      this.sortKey = sortKey
      this.sortType = sortType
      this.filterValues = filterValues
    } finally {
      this.isLoading = false
    }
  }

  async cancelSort(): Promise<void> {
    this.isLoading = true
    try {
      await conditionsStore.fetch({ date: dayjs(), page: 1 })
      this.sortKey = undefined
      this.sortType = undefined
      this.filterValues = undefined
    } finally {
      this.isLoading = false
    }
  }
}
