



































import Spinner from '@/components/atoms/Spinner.vue'
import Modal from '@/components/molecules/Modal.vue'
import Csv from '@/libs/Csv'
import conditionsStore, { ErrorMsg } from '@/store/Conditions'
import dayjs from '@/libs/dayjs'
import { Component, Vue } from 'vue-property-decorator'
import Datepicker from 'vuejs-datepicker'
import { ja } from 'vuejs-datepicker/dist/locale'

@Component({
  components: { Datepicker, Spinner, Modal },
})
export default class extends Vue {
  isLoading = false
  ja = ja
  fromDate = dayjs().subtract(1, 'M').add(1, 'd').format('YYYY/MM/DD')
  toDate = dayjs().format('YYYY/MM/DD')
  error = ''

  datepickerFormatter(date: string): string {
    return dayjs(date).format('YYYY/MM/DD')
  }

  async downloadCSV(): Promise<void> {
    const startDate = dayjs(this.fromDate)
    const endDate = dayjs(this.toDate)

    console.log(startDate, endDate);

    this.error = ''

    this.isLoading = true
    try {
      await conditionsStore.fetchHomeCsv({ startDate, endDate })
    } catch (e) {
      switch (e) {
        case ErrorMsg.responseError(460):
          this.error = ErrorMsg.OverMaxCountCsvError
          return
        case ErrorMsg.responseError(502):
          this.error = ErrorMsg.BusyCsvDownloadProcessError
          return
        default:
          this.error = ErrorMsg.FailedToDownloadCsv
          return
      }
    } finally {
      this.isLoading = false
    }

    const csv = this.createCsv()

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([csv], { type: 'text/csv' }))
    link.download = 'Conditions.csv'
    link.click()
  }

  private createCsv(): string {
    const csvHeader = ['ID', '氏名', '日付・時刻', '体温(体調情報)', '症状(体調情報)', 'メッセージ']
    const csvData = conditionsStore.homeCsvData.map((d, i) => {
      return [
        i + 1,
        d.userName,
        dayjs(d.registrationDatetime).format('YYYY/MM/DD HH:mm:ss'),
        d.bodyTemp.toFixed(1),
        d.joinedSymptoms,
        d.message,
      ]
    })

    return Csv.create(csvHeader, csvData)
  }
}
