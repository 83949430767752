












import { Component, Vue } from 'vue-property-decorator'
import Chart from '@/components/atoms/Chart.vue'
import VABox from 'va/widgets/VABox.vue'
import { ChartConfiguration } from 'chart.js'
import { pipe, head } from 'lodash/fp'
import groupBodyTempDistributionsStore from '@/store/GroupBodyTempDistributions'
import groupWithSymptomDistributionsStore from '@/store/GroupWithSymptomDistributions'

@Component({
  components: { Chart, VABox },
})
export default class extends Vue {
  get width(): number {
    return offsetWidth + groupBodyTempDistributionsStore.groupBodyTempDistributions.length * 2 * maxBarThickness
  }
  /** グラフの設定 */
  get chartConfig(): ChartConfiguration {
    const groupWithSymptomDistributions = groupWithSymptomDistributionsStore.groupWithSymptomDistributions
    const groupBodyTempDistributionsDataSet =
      groupBodyTempDistributionsStore.sortedGroupBodyTempDistributions.reduce<TGroupBodyTempDistributionsDataSet>(
        (pv, v) => {
          const bodyTempDistribution = head(v.distributions)
          const withSymptomDistribution = pipe(
            () => groupWithSymptomDistributions.find((groupWithSymptom) => groupWithSymptom.id === v.id)?.distributions,
            head
          )()
          return {
            feverList: [...pv.feverList, [bodyTempDistribution?.fever || 0, 0]],
            lowGradeFeverList: [...pv.lowGradeFeverList, [bodyTempDistribution?.lowGradeFever || 0, 0]],
            normalList: [...pv.normalList, [bodyTempDistribution?.normal || 0, 0]],
            unregisteredList: [
              ...pv.unregisteredList,
              [bodyTempDistribution?.unregistered || 0, bodyTempDistribution?.unregistered || 0],
            ],
            withSymptomList: [...pv.withSymptomList, [0, withSymptomDistribution?.withSymptom || 0]],
            noSymptomList: [...pv.noSymptomList, [0, withSymptomDistribution?.noSymptom || 0]],
            groupNames: [...pv.groupNames, [v.name, '']],
          }
        },
        {
          feverList: [],
          lowGradeFeverList: [],
          normalList: [],
          unregisteredList: [],
          withSymptomList: [],
          noSymptomList: [],
          groupNames: [],
        }
      )

    return {
      type: 'bar',
      data: {
        datasets: [
          {
            label: '発熱',
            backgroundColor: colors.fever,
            data: groupBodyTempDistributionsDataSet.feverList.flat(),
            maxBarThickness,
          },
          {
            label: '微熱',
            backgroundColor: colors.lowGradeFever,
            data: groupBodyTempDistributionsDataSet.lowGradeFeverList.flat(),
            maxBarThickness,
          },
          {
            label: '正常',
            backgroundColor: colors.normal,
            data: groupBodyTempDistributionsDataSet.normalList.flat(),
            maxBarThickness,
          },
          {
            label: '症状あり',
            backgroundColor: colors.withSymptoms,
            data: groupBodyTempDistributionsDataSet.withSymptomList.flat(),
            maxBarThickness,
          },
          {
            label: '症状なし',
            backgroundColor: colors.noSymptoms,
            data: groupBodyTempDistributionsDataSet.noSymptomList.flat(),
            maxBarThickness,
          },
          {
            label: '未登録',
            backgroundColor: colors.unregistered,
            data: groupBodyTempDistributionsDataSet.unregisteredList.flat(),
            maxBarThickness,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
                callback: (label) => {
                  if (Math.floor(Number(label)) === label) {
                    return label
                  }
                },
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              type: 'category',
              labels: groupBodyTempDistributionsDataSet.groupNames.flat(),
              gridLines: {
                display: true,
                color: ['#CCC', ...groupBodyTempDistributionsDataSet.groupNames.flatMap(() => ['#FFF', '#CCC'])],
              },
            },
          ],
        },
        maintainAspectRatio: false,
        legend: { position: 'left' },
      },
    }
  }
}

/** チャートコンフィグ用のデータセットの型 */
type TGroupBodyTempDistributionsDataSet = {
  feverList: number[][]
  lowGradeFeverList: number[][]
  normalList: number[][]
  unregisteredList: number[][]
  withSymptomList: number[][]
  noSymptomList: number[][]
  groupNames: string[][]
}

/** 各グラフの色 */
const colors = {
  fever: '#ff4800',
  lowGradeFever: '#ffd000',
  normal: '#00aeff',
  unregistered: '#EEEEEE',
  withSymptoms: '#E947B8',
  noSymptoms: '#156FE9',
}

/** 棒グラフの最大幅 */
const maxBarThickness = 70
const offsetWidth = 125
