import store from '@/store'
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import { Dayjs } from '@/libs/dayjs'
import axios from 'axios'
import config from '@/config'

/** ユーザー体温分布の型 */
export type TBodyTempDistribution = {
  fever: number
  lowGradeFever: number
  normal: number
  unregistered: number
  date: string
}

@Module({ dynamic: true, namespaced: true, name: 'bodyTempDistribution', store })
class Mod extends VuexModule {
  bodyTempDistributions: TBodyTempDistribution[] = []

  @Mutation
  setBodyTempDistributions(bodyTempDistributions: TBodyTempDistribution[]): void {
    this.bodyTempDistributions = bodyTempDistributions
  }

  /** ユーザーの体温分布のデータを読み込む */
  @Action({ commit: 'setBodyTempDistributions' })
  async fetch(data: { startDate: Dayjs; endDate: Dayjs }): Promise<TBodyTempDistribution[]> {
    const url =
      `${config.enterprise.apiUrl}/body-temp-distribution` +
      `/${data.startDate.format('YYYYMMDD')}` +
      `/${data.endDate.format('YYYYMMDD')}`
    const res = await axios.get(url)
    if (res.status !== 200) {
      throw 'API response error status:' + res.status
    }
    return res.data
  }
}

export default getModule(Mod)
