










import { Component, Vue } from 'vue-property-decorator'
import VABox from 'va/widgets/VABox.vue'
import Chart from '@/components/atoms/Chart.vue'
import { ChartConfiguration } from 'chart.js'
import { last } from 'lodash'
import bodyTempDistributionsStore from '@/store/BodyTempDistributions'

@Component({
  components: { Chart, VABox },
})
export default class extends Vue {
  /** グラフの全体数 */
  get total(): number | string {
    const bodyTempDistribution = last(bodyTempDistributionsStore.bodyTempDistributions)
    if (!bodyTempDistribution) {
      return ''
    }
    const { fever, lowGradeFever, normal, unregistered } = bodyTempDistribution
    return fever + lowGradeFever + normal + unregistered
  }

  /** グラフの設定 */
  get chartConfig(): ChartConfiguration {
    const bodyTempDistribution = last(bodyTempDistributionsStore.bodyTempDistributions)
    const bodyTempList = bodyTempDistribution
      ? [
          bodyTempDistribution.fever,
          bodyTempDistribution.lowGradeFever,
          bodyTempDistribution.normal,
          bodyTempDistribution.unregistered,
        ]
      : []

    return {
      type: 'doughnut',
      data: {
        datasets: [
          {
            backgroundColor: [colors.fever, colors.low_grade_fever, colors.normal, colors.unregistered],
            data: bodyTempList,
          },
        ],
        labels: ['発熱', '微熱', '正常', '未登録'],
      },
      options: { plugins: { center: { text: this.total } } },
    }
  }
}

/** 各グラフの色 */
const colors = {
  fever: '#ff4800',
  low_grade_fever: '#ffd000',
  normal: '#00aeff',
  unregistered: '#EEEEEE',
}
