import store from '@/store'
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators'
import { Dayjs } from '@/libs/dayjs'
import axios from 'axios'
import config from '@/config'

/** グループ体温分布の型 */
export type TGroupWithSymptomDistribution = {
  id: string
  name: string
  distributions: TWithSymptomDistribution[]
}

export type TWithSymptomDistribution = {
  withSymptom: number
  noSymptom: number
  unregistered: number
  date: string
}

@Module({ dynamic: true, namespaced: true, name: 'groupWithSymptomDistribution', store })
class Mod extends VuexModule {
  groupWithSymptomDistributions: TGroupWithSymptomDistribution[] = []

  @Mutation
  setGroupWithSymptomDistributions(groupWithSymptomDistributions: TGroupWithSymptomDistribution[]): void {
    this.groupWithSymptomDistributions = groupWithSymptomDistributions
  }

  /** グループの症状分布のデータを読み込む */
  @Action({ commit: 'setGroupWithSymptomDistributions' })
  async fetch(data: { fromDate: Dayjs; toDate: Dayjs; groupId?: string }): Promise<TGroupWithSymptomDistribution[]> {
    const url = `${config.enterprise.apiUrl}/group-with-symptom-distribution`
    const res = await axios.get(url, {
      params: {
        groupId: data.groupId,
        fromDate: data.fromDate.format('YYYYMMDD'),
        toDate: data.toDate.format('YYYYMMDD'),
      },
    })
    if (res.status !== 200) {
      throw 'API response error status:' + res.status
    }

    return res.data.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (v: any): TGroupWithSymptomDistribution => ({
        id: v.id,
        name: v.name,
        distributions: v.distributions.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (distribution: any): TWithSymptomDistribution => ({
            date: distribution.date,
            withSymptom: distribution.withSymptom,
            noSymptom: distribution.noSymptom,
            unregistered: distribution.unregistered,
          })
        ),
      })
    )
  }
}

export default getModule(Mod)
