










import { Component, Vue } from 'vue-property-decorator'
import Chart from '@/components/atoms/Chart.vue'
import VABox from 'va/widgets/VABox.vue'
import { ChartConfiguration } from 'chart.js'
import dayjs from '@/libs/dayjs'
import bodyTempDistributionsStore from '@/store/BodyTempDistributions'

@Component({
  components: { Chart, VABox },
})
export default class extends Vue {
  /** グラフの設定 */
  get chartConfig(): ChartConfiguration {
    const bodyTempDistributionsDataSet =
      bodyTempDistributionsStore.bodyTempDistributions.reduce<TBodyTempDistributionsDataSet>(
        (pv, v) => ({
          feverList: [...pv.feverList, v.fever],
          lowGradeFeverList: [...pv.lowGradeFeverList, v.lowGradeFever],
          normalList: [...pv.normalList, v.normal],
          unregisteredList: [...pv.unregisteredList, v.unregistered],
          dateList: [...pv.dateList, dayjs(v.date).format('M/D')],
        }),
        {
          feverList: [],
          lowGradeFeverList: [],
          normalList: [],
          unregisteredList: [],
          dateList: [],
        }
      )

    return {
      type: 'line',
      data: {
        datasets: [
          {
            label: '発熱',
            backgroundColor: colors.fever,
            borderColor: colors.fever,
            data: bodyTempDistributionsDataSet.feverList,
          },
          {
            label: '微熱',
            backgroundColor: colors.low_grade_fever,
            borderColor: colors.low_grade_fever,
            data: bodyTempDistributionsDataSet.lowGradeFeverList,
          },
          {
            label: '正常',
            backgroundColor: colors.normal,
            borderColor: colors.normal,
            data: bodyTempDistributionsDataSet.normalList,
          },
          {
            label: '未登録',
            backgroundColor: colors.unregistered,
            borderColor: colors.unregistered,
            data: bodyTempDistributionsDataSet.unregisteredList,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
                callback: (label) => {
                  if (Math.floor(Number(label)) === label) {
                    return label
                  }
                },
              },
            },
          ],
          xAxes: [
            {
              type: 'category',
              labels: bodyTempDistributionsDataSet.dateList,
            },
          ],
        },
        // ベジェ曲線無効
        elements: { line: { tension: 0 } },
      },
    }
  }
}

/** チャートコンフィグ用のデータセットの型 */
type TBodyTempDistributionsDataSet = {
  feverList: number[]
  lowGradeFeverList: number[]
  normalList: number[]
  unregisteredList: number[]
  dateList: string[]
}

/** 各グラフの色 */
const colors = {
  fever: '#ff4800',
  low_grade_fever: '#ffd000',
  normal: '#00aeff',
  unregistered: '#EEEEEE',
}
