




























import { Component, Vue } from 'vue-property-decorator'
import Spinner from '@/components/atoms/Spinner.vue'
import dayjs from '@/libs/dayjs'
import BodyTempDistributionChart from '@/components/organisms/Home/BodyTempDistributionChart.vue'
import GroupBodyTempDistributionChart from '@/components/organisms/Home/GroupBodyTempDistributionChart.vue'
import BodyTempChart from '@/components/organisms/Home/BodyTempChart.vue'
import ConditionTable from '@/components/organisms/Home/ConditionTable.vue'
import SymptomDistributionsChart from '@/components/organisms/Home/SymptomDistributionsChart.vue'
import CurrentDate from '@/components/molecules/CurrentDate.vue'
import Csv from '@/components/organisms/Home/Csv.vue'
import menuStore from '@/store/Menu'
import bodyTempDistributionsStore from '@/store/BodyTempDistributions'
import groupBodyTempDistributionsStore from '@/store/GroupBodyTempDistributions'
import groupWithSymptomDistributionsStore from '@/store/GroupWithSymptomDistributions'
import conditionsStore from '@/store/Conditions'
import symptomDistributionsStore from '@/store/SymptomDistributions'
import organizationUsersStore from '@/store/OrganizationUsers'
import symptomsStore from '@/store/Symptoms'
import TutorialModal from '@/components/organisms/Layout/TutorialModal.vue'

@Component({
  components: {
    BodyTempDistributionChart,
    GroupBodyTempDistributionChart,
    BodyTempChart,
    SymptomDistributionsChart,
    ConditionTable,
    CurrentDate,
    Csv,
    Spinner,
    TutorialModal,
  },
})
export default class extends Vue {
  isLoading = false

  async mounted(): Promise<void> {
    // 初期化
    menuStore.setActiveFlg({ home: true, person: false, group: false })
    menuStore.setGroups([])
    menuStore.setPersons([])
    bodyTempDistributionsStore.setBodyTempDistributions([])
    groupBodyTempDistributionsStore.setGroupBodyTempDistributions([])
    conditionsStore.setConditions([])
    conditionsStore.setMeta({ page: 1, lastPage: 1 })
    symptomsStore.setSymptoms([])

    const endDate = dayjs()
    const startDate = dayjs().subtract(13, 'd')
    const page = 1

    this.isLoading = true
    // ユーザー体温分布をAPIから取得
    try {
      await Promise.all([
        bodyTempDistributionsStore.fetch({ startDate, endDate }),

        // グループ体温分布をAPIから取得
        groupBodyTempDistributionsStore.fetch({ date: endDate }),
        // グループ症状分布をAPIから取得
        groupWithSymptomDistributionsStore.fetch({ toDate: endDate, fromDate: endDate }),

        // ユーザの体温入力記録をAPIから取得
        conditionsStore.fetch({ date: endDate, page }),

        // 症状者数推移をAPIから取得
        symptomDistributionsStore.fetch({ startDate, endDate }),

        organizationUsersStore.getCurrentRole(),

        symptomsStore.fetch(),
      ])
    } finally {
      this.isLoading = false
    }

    // サイドメニューの設定
    menuStore.fetchGroups()
  }
}
